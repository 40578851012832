/* eslint-disable  */
import searchTranslations from '@vaimo-algolia/i18n/en';

// TODO: This is a temporary solution for checkout page
const checkoutTranslations = {
  'Return to Basket': 'Return to Basket',
  'Address Name': 'Address Name',
  'Alternative phone number': 'Alternative phone number',
  'Choose an option': 'Choose an option',
  'For delivery': 'For delivery',
  'Collect from shop': 'Collect from shop',
  'Pickup location': 'Pickup location',
  'Get Directions': 'Get Directions',
  'This helps you quickly recognise different delivery addresses, for example Home': 'This helps you quickly recognise different delivery addresses, for example Home',
};

export default {
  "{0} x {1} cl": "{0} x {1} cl",
  "cases": "cases",
  "(Drink {0} - {1})": "(Drink {0} - {1})",
  "About us": "About us",
  "Accepting terms and conditions required": "Accepting terms and conditions required",
  "Account": "Account",
  "Registration": {
    "Registration successful!": "Registration successful!",
    "Welcome to our platform.": "Welcome to our platform.",
    "Password": {
      "Password is required": "Password is required",
      "Passwords are not the same": "Passwords are not the same",
      "Must contain at least one uppercase letter": "Must contain at least one uppercase letter",
      "Must contain at least one lowercase letter": "Must contain at least one lowercase letter",
      "Must contain at least one number": "Must contain at least one number",
      "Must contain at least one special character ($, /, &, etc.)": "Must contain at least one special character ($, /, &, etc.)"
    }
  },
  "Add new address": "Add new address",
  "Add to compare": "Add to compare",
  "Add to wishlist": "Add to wishlist",
  "Additional Information": "Additional Information",
  "After commission": "After commission",
  "After commission ({0}%)": "After commission ({0}%)",
  "All bids received": "All bids received",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "Apply": "Apply",
  "Applied Coupon": "Applied Coupon",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Back to homepage",
  "BBX our fine wine & spirits exchange": "BBX our fine wine & spirits exchange",
  "What is the difference between Berry Bros. & Rudd and BBX?": "What is the difference between Berry Bros. & Rudd and BBX?",
  "What price would you like to list your case at?": "What price would you like to list your case at?",
  "Which format would you like to bid on?": "Which format would you like to bid on?",
  "Berry Bros. & Rudd": "Berry Bros. & Rudd",
  "BBX Marketplace": "BBX Marketplace",
  "BBX our fine wine exchange contains privately owned wines that our customers have listed for sale.": "BBX our fine wine exchange contains privately owned wines that our customers have listed for sale.",
  "Billing": "Billing",
  "Billing address": "Billing address",
  "Brand": "Brand",
  "Cancel": "Cancel",
  "Cart": "Cart",
  "Categories": "Categories",
  "Change": "Change",
  "Change password your account": "If you want to change the password to access your account, enter the following information",
  "Clear all": "Clear all",
  "Color": "Color",
  "Open filters": "Open filters",
  "Commercial information": "and agree to receive personalized commercial information from Brand name by email",
  "Contact details updated": "Keep your addresses and contact details updated.",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Create an account",
  "Customer Reviews": "Customer Reviews",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Delete": "Delete",
  "Delivery address": "Delivery address",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  "Discount": "Discount",
  "Done": "Done",
  "Download": "Download",
  "Download all": "Download all",
  "Edit": "Edit",
  "Edit bid": "Edit bid",
  "Email address": "Email address",
  "Empty": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Empty bag": "Empty bag",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Enter promo code",
  "Enter code": "Enter code",
  "Savings": "Savings",
  "Your applied vouchers": "Your applied vouchers",
  "Successfully removed your voucher code [{0}]": "Successfully removed your voucher code [{0}]",
  "Successfully applied your voucher code [{0}]": "Successfully applied your voucher code [{0}]",
  "Your voucher code [{0}] is not valid": "Your voucher code [{0}] is not valid",
  "Excl. duty & VAT": "Excl. duty & VAT",
  "Duty & VAT only": "Duty & VAT only",
  "Remove voucher": "Remove voucher",
  "Feedback": "Your feedback is important to us. Let us know what we could improve.",
  "Feel free to edit": "Feel free to edit any of your details below so your account is always up to date",
  "Filters": "Filters",
  "See the market value and place a bid for this wine on": "See the market value and place a bid for this wine on",
  "Find out more": "Find out more",
  "First Name": "First Name",
  "If you can't remember your password, you can reset it.": "If you can't remember your password, you can reset it.",
  "Forgot Password Modal Email": "Email you are using to sign in:",
  "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
  "Forgotten password": "Forgotten password",
  "Create an account": "Create an account",
  "Go back to login": "Go back to login",
  "Go back": "Go back",
  "Go back shopping": "Go back shopping",
  "Go back to shop": "Go back to shop",
  "Go to checkout": "Go to checkout",
  "Go to cart": "Go to cart",
  "Guarantee": "Guarantee",
  "Help": "Help",
  "Help & FAQs": "Help & FAQs",
  "hide": "hide",
  "Home": "Home",
  "I agree to": "I agree to",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "Info after order": "You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.",
  "You paid": "You paid",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Last sale (90 days)": "Last sale (90 days)",
  "Learn more about BBX": "Learn more about BBX",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Log into your account": "Log into your account",
  "Login": "Login",
  "login in to your account": "login in to your account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Lower bids": "Lower bids",
  "Lowest BBX price": "Lowest BBX price",
  "Lowest list price": "Lowest list price",
  "Make an order": "Make an order",
  "Manage alerts": "Manage alerts",
  "Manage addresses": "Manage all the addresses you want (work place, home address...) This way you won\"t have to enter the address manually with each order.",
  "Manage bid alerts for this listing": "Manage bid alerts for this listing",
  "Manage billing addresses": "Manage all the billing addresses you want (work place, home address...) This way you won\"t have to enter the billing address manually with each order.",
  "Manage my bids": "Manage my bids",
  "My bids": "My bids",
  "Manage shipping addresses": "Manage all the shipping addresses you want (work place, home address...) This way you won\"t have to enter the shipping address manually with each order.",
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "My billing and shipping address are the same": "My billing and shipping address are the same",
  "My Cart": "My Cart",
  "My total cases": "My total cases",
  "Don't have an account yet?": "Don't have an account yet?",
  "or": "or",
  "or fill the details below": "or fill the details below",
  "Order ID": "Order ID",
  "Order information": "Order information",
  "Order No.": "Order No.",
  "Order summary": "Order summary",
  "Other products you might like": "Other products you might like",
  "Out of stock": "Out of stock",
  "Password": "Password",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Pay for order": "Pay for order",
  "Payment": "Payment",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Personal details": "Personal details",
  "Place a bid": "Place a bid",
  "Please enter a bid": "Please enter a bid",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  "Price": "Price",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Product": "Product",
  "Product suggestions": "Product suggestions",
  "Products": "Products",
  "Products found": "Products found",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Quantity available": "Quantity available",
  "Increase quantity": "Increase quantity",
  "Declining": "Declining",
  "Decrease quantity": "Decrease quantity",
  "Quantity input": "Quantity input",
  "Read all reviews": "Read all reviews",
  "Read and understand": "I have read and understand the",
  "Read reviews": "Read reviews",
  "Register": "Register",
  "Register today": "Register today",
  "Remove": "Remove",
  "Remove Address": "Remove Address",
  "Remove from wishlist": "Remove from wishlist",
  "Repeat Password": "Repeat Password",
  "Reset password": "Reset password",
  "Review my order": "Review my order",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search": "Search",
  "Search for items": "Search for items",
  "Search results": "Search results",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "See more options": "See more options",
  "See fewer options": "See fewer options",
  "Select payment method": "Select payment method",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter": "Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.",
  "Share your look": "Share your look",
  "Shipping": "Shipping",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Show": "Show",
  "Show breadcrumbs": "Show breadcrumbs",
  "show more": "show more",
  "Show {0} items per page": "Show {0} items per page",
  "Show on page": "Show on page",
  "Sign in": "Sign in",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Sort by": "Sort by",
  "Sort: Default": "Default",
  "Sort: Name A-Z": "Name A-Z",
  "Sort: Name Z-A": "Name Z-A",
  "Sort: Price from high to low": "Price from high to low",
  "Sort: Price from low to high": "Price from low to high",
  "Start live chat": "Start live chat",
  "Start shopping": "Start shopping",
  "I do not want to receive emails": "I do not want to receive emails",
  "I do not want to receive post": "I do not want to receive post",
  "Status": "Status",
  "Subscribe": "Subscribe",
  "Subscribe to newsletter": "Subscribe to newsletter",
  "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Subtotal price",
  "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "Tasting note": "Tasting note",
  "Terms and conditions": "Terms and conditions",
  "Thank you": "Thank you",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  "Total": "Total",
  "Close titles dropdown": "Close titles dropdown",
  "Order Total": "Order Total",
  "Total items": "Total items",
  "Total price": "Total price",
  "Update password": "Update password",
  "Update personal data": "Update personal data",
  "Use your personal data": "At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the",
  "User Account": "User Account",
  "View": "View",
  "View bids": "View bids",
  "View details": "View details",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "Women fashion": "Women fashion",
  "Calculated at checkout": "Calculated at checkout",
  "Secure checkout": "Secure checkout",
  "In Bond": "In Bond",
  "VAT": "VAT",
  "Warehouse": "Warehouse",
  "temperature-controlled warehouse": "temperature-controlled warehouse",
  "future storage charges": "future storage charges",
  "duty and VAT charges": "duty and VAT charges",
  "Your items in Bond will incur {charges}.": "Your items in Bond will incur {charges}.",
  "In Bond purchases can be stored in our {warehouse}.": "In Bond purchases can be stored in our {warehouse}.",
  "In Bond purchases will remain stored in our {warehouse}.": "In Bond purchases will remain stored in our {warehouse}.",
  "This commission is not subject to VAT: another crucial differential from some other exchanges. There are no hidden fees.": "This commission is not subject to VAT: another crucial differential from some other exchanges. There are no hidden fees.",
  "This item is stored on behalf of {0}": "This item is stored on behalf of {0}",
  "This means that In Bond purchases will incur {charges}.": "This means that In Bond purchases will incur {charges}.",
  "All In Bond purchase prices are exclusive of {charges}, which will be paid on request of delivery.": "All In Bond purchase prices are exclusive of {charges}, which will be paid on request of delivery.",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in": "You are not authorized, please log in",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no bids placed": "You currently have no bids placed",
  "You have accepted the bid.": "You have accepted the bid.",
  "You have no bids on your wines": "You have no bids on your wines",
  "You currently have no orders": "You currently have no orders",
  "You haven\’t searched for items yet": "You haven’t searched for items yet.",
  "Your bag is empty": "Your bag is empty",
  "Your bid per case": "Your bid per case",
  "Your current email address is": "Your current email address is",
  "Your email": "Your email",
  "Your list price": "Your list price",
  "Your list price per case": "Your list price per case",
  "Your listing has been edited.": "Your listing has been edited.",
  "Your total cases": "Your total cases",
  "The email field must be a valid email": "The email field must be a valid email",
  'You have submitted no reviews': 'You have submitted no reviews',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  "A customer with the same email address already exists in an associated website.": "A customer with the same email address already exists in an associated website.",
  "Invalid email": "Invalid email",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Learn more": "Learn more",
  "Dresses": "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "Shop now": "Shop now",
  "The Office Life": "The Office Life",
  "Summer Sandals": "Summer Sandals",
  "Eco Sandals": "Eco Sandals",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Fashion to take away": "Fashion to take away",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "My Account": "My Account",
  "My profile": "My profile",
  "Personal Details": "Personal Details",
  "Addresses details": "Addresses details",
  "My newsletter": "My newsletter",
  "Log out": "Log out",
  "My reviews": "My reviews",
  "Order history": "Order history",
  "Order details": "Order details",
  "My wishlist": "My wishlist",
  "Password change": "Password change",
  "Personal data": "Personal data",
  "Your e-mail": "Your e-mail",
  "Current bidders": "Current bidders",
  "Current highest bid": "Current highest bid",
  "Current Password": "Current Password",
  "You are not authorized, please log in.": "You are not authorized, please log in.",
  "Go To Product": "Go To Product",
  "Change to list view": "Change to list view",
  "Change to grid view": "Change to grid view",
  "Returns": "Returns",
  "My orders": "My orders",
  "You have no wines": "You have no wines",
  "Add the address": "Add the address",
  "Set as default shipping": "Set as default shipping",
  "Set as default billing": "Set as default billing",
  "House/Apartment number": "House/Apartment number",
  "Street Name": "Street Name",
  "City": "City",
  "State/Province": "State/Province",
  "Zip-code": "Zip-code",
  "Country": "Country",
  "Phone number": "Phone number",
  "Please select a country first": "Please select a country first",
  "This field is required": "This field is required",
  "The field should have at least 2 characters": "The field should have at least 2 characters",
  "The field should have at least 4 characters": "The field should have at least 4 characters",
  "The field should have at least 8 characters": "The field should have at least 8 characters",
  "New Password": "New Password",
  "New Products": "New Products",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "We can\'t find products matching the selection.": "We can\'t find products matching the selection.",
  "We’ve sent you a confirmation email for this pending sale.": "We’ve sent you a confirmation email for this pending sale.",
  'Page not found': 'Page not found',
  'Back to Home page': 'Back to Home page',
  'An error occurred': 'An error occurred',
  "AllProductsFromCategory": "All {categoryName}",
  "Show more": "Show more",
  "Show less": "Show less",
  "Go to next page": "Go to next page",
  "Go to previous page": "Go to previous page",
  "Previous": "Previous",
  "Next": "Next",
  "Yes": "Yes",
  "No": "No",
  "Apply filters": "Apply filters",
  "Clear all filters": "Clear all filters",
  "Applied Filters:": "Applied Filters:",
  "View results": "View results",
  "Apply filter": "Apply filter",
  "Clear filter": "Clear filter",
  "Change sort by": "Change sort by",
  "Back": "Back",
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  "From": "From",
  "To": "To",
  "Your customization": "Your customization",
  "Passwords don't match": "Passwords don't match",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)": "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)",
  "Show all products": "Show all products",
  "Select previously saved address": "Select previously saved address",
  "Enter different address": "Enter different address",
  "You must confirm your account. Please check your email for the confirmation link.": "You must confirm your account. Please check your email for the confirmation link.",
  "Change Store": "Change Store",
  "Choose Currency": "Choose Currency",
  "Add a review": "Add a review",
  "Add to cart": "Add to cart",
  "Title": "Title",
  "Name": "Name",
  "Review": "Review",
  "Add review": "Add review",
  "Are you sure you would like to remove this item from the shopping cart?": "Are you sure you would like to remove this item from the shopping cart?",
  "Your cart is empty": "Your cart is empty",
  "Are you sure?": "Are you sure?",
  "{0} has been successfully removed from your cart": "{0} has been successfully removed from your cart",
  "Amount": "Amount",
  "Thank you for your order!": "Thank you for your order!",
  "Your Purchase": "Your Purchase",
  "Primary contacts for any questions": "Primary contacts for any questions",
  "Your Account": "Your account",
  "What can we improve": "What can we improve",
  "Payment date": "Payment date",
  "The user password was changed successfully updated!": "The user password was changed successfully updated!",
  "The user account data was successfully updated!": "The user account data was successfully updated!",
  "You submitted your review for moderation.": "You submitted your review for moderation.",
  "Starting at": "Starting at",
  "The site was opened in the preview mode": "The site was opened in the preview mode",
  "Back to live": "Back to live",
  "Ingredients": "Ingredients",
  "Preparation": "Preparation",
  "Account Links": "Account Links",
  "Sign out": "Sign out",
  "Show all": "Show all",
  "Close": "Close",
  "Filter by topic": "Filter by topic",
  "Search for topic..": "Search for topic..",
  "No Result": "No Result",
  "Sort by date": "Sort by date",
  "Latest to oldest": "Latest to oldest",
  "Oldest to latest": "Oldest to latest",
  "Showing {0}-{1} of {2} articles": "Showing {0}-{1} of {2} articles",
  "Showing {0}-{1} of {2} products": "Showing {0}-{1} of {2} products",
  "Wine at a glance": "Wine at a glance",
  "Product: {0}": "Product: {0}",
  "SEARCH OR BROWSE {0}": "SEARCH OR BROWSE {0}",
  "Available for delivery or collection. Pricing includes duty and VAT.": "Available for delivery or collection. Pricing includes duty and VAT.",
  "Available by the case In Bond. Pricing excludes duty and VAT, which must be paid separately before delivery.": "Available by the case In Bond. Pricing excludes duty and VAT, which must be paid separately before delivery.",
  "Storage charges apply.": "Storage charges apply.",
  "Storage on this item is paid for by {0}": "Storage on this item is paid for by {0}",
  "Commonly bought together": "Commonly bought together",
  "There are currently no items in your basket": "There are currently no items in your basket",
  "Disabled action slot": "Disabled action slot",
  "My basket": "My basket",
  "{0} item": "{0} item",
  "{0} items": "{0} items",
  "Order total": "Order total",
  "Check out": "Check out",
  "Qty: {0}": "Qty: {0}",
  "Promotion or voucher code": "Promotion or voucher code",
  "Regular price: {0}": "Regular price: {0}",
  "Special price: {0}": "Special price: {0}",
  "Attributes:": "Attributes:",
  "Get in touch with us": "Get in touch with us",
  "Sign up": "Sign up",
  "Delivery": "Delivery",
  "Add": "Add",
  "Search categories": "Search categories",
  "Recommended for you": "Recommended for you",
  "Opening hours": "Opening hours",
  "Applied filters:": "Applied filters:",
  'Showing 1 product of 1': 'Showing 1 product of 1',
  'My BBX Account': "My BBX Account",
  "View my listings": "View my listings",
  "View my bids": "View my bids",
  'Your Cellared Wines': 'Your Cellared Wines',
  'Your BBX Dashboard': 'Your BBX Dashboard',
  'My BBX Dashboard': 'My BBX Dashboard',
  'Search for product..': 'Search for product..',
  'Relevance': 'Relevance',
  'Price (Low - High)': 'Price (Low - High)',
  'Price (High - Low)': 'Price (High - Low)',
  'Vintage Ascending': 'Vintage Ascending',
  'Vintage Descending': 'Vintage Descending',
  'Maturity': 'Maturity',
  'Producer A-Z': 'Producer A-Z',
  'Product A-Z': 'Product A-Z',
  'Products per page': 'Products per page',
  "Funds": "Funds",
  "Preferences": "Preferences",
  "Your Details": "Your Details",
  "Direct Debit": "Direct Debit",
  "Confirmation": "Confirmation",
  "Confirm bid": "Confirm bid",
  "Confirm listing": "Confirm listing",

  /* Cellar plan */
  "1. Where are you based?": "1. Where are you based?",
  "2. How do you want to collect?": "2. How do you want to collect?",
  "3. How much do you want to spend?": "3. How much do you want to spend?",
  "4. Payment details": "4. Payment details",
  "5. Important to know": "5. Important to know",
  "BB&R choose": "BB&R choose",
  "Cellar plan benefits": "Cellar plan benefits",
  "Choose your location": "Choose your location",
  "For our most popular offers that sell out quickly, you may miss out.": "For our most popular offers that sell out quickly, you may miss out.",
  "If you have questions about joining Cellar Plan, or collecting wines and spirits, you can {action} with our Cellar Plan team.": "If you have questions about joining Cellar Plan, or collecting wines and spirits, you can {action} with our Cellar Plan team.",
  "Join cellar plan": "Join cellar plan",
  "Our Cellar Plan team is here to help": "Our Cellar Plan team is here to help",
  "Outside the UK": "Outside the UK",
  "Payment schedule": "Payment schedule",
  "Recommended": "Recommended",
  "Return to {0}": "Return to {0}",
  "Select BB&R choose": "Select BB&R choose",
  "Select wines and spirits from our email offers and add them to your cellar.": "Select wines and spirits from our email offers and add them to your cellar.",
  "Select you choose": "Select you choose",
  "Tailor your interests to adjust which offers you receive.": "Tailor your interests to adjust which offers you receive.",
  "The Cellar Plan team add wines and spirits to your collection according to your interests, spending level and cellar goals.": "The Cellar Plan team add wines and spirits to your collection according to your interests, spending level and cellar goals.",
  "There are two ways to add wine and spirits to your cellar. You can change your preference easily at any time.": "There are two ways to add wine and spirits to your cellar. You can change your preference easily at any time.",
  "There are two ways to add wine and spirits to your cellar. You can change your preference easily at any time.": "There are two ways to add wine and spirits to your cellar. You can change your preference easily at any time.",
  "UK": "UK",
  "We build your cellar together, combining your interests and our expertise.": "We build your cellar together, combining your interests and our expertise.",
  "We do not have an office in this country and therefore many elements of the Cellar Plan experience will not be available. We do not ship to this country so accessing your cellar will not be possible if you would like the wine shipped to this country. Our Cellar Plan Team are based in the UK and operate between 9am and 5pm UK time.": "We do not have an office in this country and therefore many elements of the Cellar Plan experience will not be available. We do not ship to this country so accessing your cellar will not be possible if you would like the wine shipped to this country. Our Cellar Plan Team are based in the UK and operate between 9am and 5pm UK time.",
  "We do not have an office in this country and therefore some elements of the Cellar Plan experience will not be available. We can ship to this country at a cost, but we do not run events here. Our Cellar Plan Team are based in the UK and operate between 9am and 5pm UK time.": "We do not have an office in this country and therefore some elements of the Cellar Plan experience will not be available. We can ship to this country at a cost, but we do not run events here. Our Cellar Plan Team are based in the UK and operate between 9am and 5pm UK time.",
  "We have an office in this country. Please note that there are elements of the Cellar Plan experience which we are unable to fulfil as we can in the UK. However, we do offer Account Management in this country. Find out more about Account Management.": "We have an office in this country. Please note that there are elements of the Cellar Plan experience which we are unable to fulfil as we can in the UK. However, we do offer Account Management in this country. Find out more about Account Management.",
  "You can easily decline any orders you do not want.": "You can easily decline any orders you do not want.",
  "You choose": "You choose",
  "You still make purchases yourself and can spend beyond your monthly contribution.": "You still make purchases yourself and can spend beyond your monthly contribution.",
  "You\'ll actively decide which wines and sprits to add to your cellar.": "You'll actively decide which wines and sprits to add to your cellar.",
  "Your Cellar Plan experience will differ if you are based outside the UK.": "Your Cellar Plan experience will differ if you are based outside the UK.",
  "Your cellar plan": "Your cellar plan",
  "Your plan": "Your plan",
  "get in touch": "get in touch",
  /* Cellar plan */

  "Please use the form below to sign up to The Cellar Plan and select your Funding Preferences. Cellar Plans start from {0}250 per month.": "Please use the form below to sign up to The Cellar Plan and select your Funding Preferences. Cellar Plans start from {0}250 per month.",
  "Cellar Plan Funds": "Cellar Plan Funds",
  "Monthly Payments": "Monthly Payments",
  "Other amount": "Other amount",
  "Initial Payment": "Initial Payment",
  "Purchasing Arrangements:": "Purchasing Arrangements:",
  "Proceed to Preferences": "Proceed to Preferences",
  "Invalid Payment amount.": "Invalid Payment amount.",
  "If you would like to get your cellar started with an upfront payment then enter the amount here. Please note that this is paid separately to your Direct Debit.": "If you would like to get your cellar started with an upfront payment then enter the amount here. Please note that this is paid separately to your Direct Debit.",
  "by selecting this option your account manager will be able to secure sought-after wines and build up your cellar on your behalf, tailored to your preferences. You will always be notified of any purchases and you have the option to cancel subject to terms.": "by selecting this option your account manager will be able to secure sought-after wines and build up your cellar on your behalf, tailored to your preferences. You will always be notified of any purchases and you have the option to cancel subject to terms.",
  "your account manager will send you offers and all purchases made under this arrangement are agreed by you": "your account manager will send you offers and all purchases made under this arrangement are agreed by you",
  "Monthly Payment amount required": "Monthly Payment amount required",
  "The minimum monthly amount is {0}250": "The minimum monthly amount is {0}250",
  "Invalid Monthly Payment amount": "Invalid Monthly Payment amount",
  "Invalid Initial Payment amount": "Invalid Initial Payment amount",
  "Purchasing notes": "Purchasing notes",
  "Once you have signed up to Cellar Plan your account manager will be in touch in the next few days to find out more about your wine and spirit preferences. In the meantime, can you tell us about the last great bottle of wine you enjoyed.": "Once you have signed up to Cellar Plan your account manager will be in touch in the next few days to find out more about your wine and spirit preferences. In the meantime, can you tell us about the last great bottle of wine you enjoyed.",
  "Proceed to Your Details": "Proceed to Your Details",
  "Cellar Plan Signup - Your Details": "Cellar Plan Signup - Your Details",
  "Please use the form below to sign up to the Cellar Plan. We recommend that you use upper and lower case for clarity. Alternatively, you can call our Cellar Plan Team on 0800 280 2440.": "Please use the form below to sign up to the Cellar Plan. We recommend that you use upper and lower case for clarity. Alternatively, you can call our Cellar Plan Team on 0800 280 2440.",
  "Choose your addresses": "Choose your addresses",
  "Cellar Plan Holder Details:": "Cellar Plan Holder Details:",
  "Delivery Instructions:": "Delivery Instructions:",
  "By supplying your e-mail address, you agree to receive your Direct Debit notifications via e-mail.": "By supplying your e-mail address, you agree to receive your Direct Debit notifications via e-mail.",
  "Proceed to Direct Debit": "Proceed to Direct Debit",
  "BB&R choose:": "BB&R choose:",
  "You Choose:": "You Choose:",
  "Cellar Plan Signup - Direct Debit": "Cellar Plan Signup - Direct Debit",
  "Please use the form below to set up your Cellar Plan Direct Debit.": "Please use the form below to set up your Cellar Plan Direct Debit.",
  "In order to set up a Direct Debit via this method, you must be the account holder for the account you specify. If more than one person is required to authorize debits from the account, please contact us by phone on 0800 280 2440 or by post at:": "In order to set up a Direct Debit via this method, you must be the account holder for the account you specify. If more than one person is required to authorize debits from the account, please contact us by phone on 0800 280 2440 or by post at:",
  "Originators Identification No:": "Originators Identification No:",
  "To:": "To:",
  "The Manager, Bank / Building Society:*": "The Manager, Bank / Building Society:*",
  "Bank Address:*": "Bank Address:*",
  "Bank Postcode:*": "Bank Postcode:*",
  "Name(s) of Account Holder(s):*": "Name(s) of Account Holder(s):*",
  "Bank Sort Code:*": "Bank Sort Code:*",
  "Bank Account No:*": "Bank Account No:*",
  "Reference:": "Reference:",
  "Instruction to your Bank or Building Society": "Instruction to your Bank or Building Society",
  "Please pay BB&R Limited Direct Debits from the account detailed in this instruction subject to the safeguards assured by the Direct Debit Guarantee. I understand that this instruction may remain with BB&R Limited and, if so, details will be passed electronically to my Bank/Building Society.": "Please pay BB&R Limited Direct Debits from the account detailed in this instruction subject to the safeguards assured by the Direct Debit Guarantee. I understand that this instruction may remain with BB&R Limited and, if so, details will be passed electronically to my Bank/Building Society.",
  "Date:": "Date:",
  "All columns marked * are required.": "All columns marked * are required.",
  "N.B. Banks and Building Societies may not accept Direct Debit Instructions for some types of account.": "N.B. Banks and Building Societies may not accept Direct Debit Instructions for some types of account.",
  "Proceed to Confirmation": "Proceed to Confirmation",
  "The Direct Debit Guarantee": "The Direct Debit Guarantee",
  "This Guarantee is offered by all Banks and Building Societies that take part in the Direct Debit Scheme.": "This Guarantee is offered by all Banks and Building Societies that take part in the Direct Debit Scheme.",
  "The efficiency and security of the Scheme is monitored and protected by your own Bank or Building Society.": "The efficiency and security of the Scheme is monitored and protected by your own Bank or Building Society.",
  "If the amounts to be paid or the payment dates change BB&R Limited will notify you ten working days in advance of your account being debited or as otherwise agreed.": "If the amounts to be paid or the payment dates change BB&R Limited will notify you ten working days in advance of your account being debited or as otherwise agreed.",
  "If an error is made by BB&R Limited or your Bank or Building Society, you are guaranteed a full and immediate refund from your branch of the amount paid.": "If an error is made by BB&R Limited or your Bank or Building Society, you are guaranteed a full and immediate refund from your branch of the amount paid.",
  "You can cancel a Direct Debit at any time, by writing to your Bank or Building Society. Please also inform BB&R Limited.": "You can cancel a Direct Debit at any time, by writing to your Bank or Building Society. Please also inform BB&R Limited.",
  "Bank Name required.": "Bank Name required.",
  "Bank Address required.": "Bank Address required.",
  "Bank Postcode required.": "Bank Postcode required.",
  "Bank Account Name required.": "Bank Account Name required.",
  "Sort Code required.": "Sort Code required.",
  "Bank Account required.": "Bank Account required.",
  "The Bank Account number entered is invalid. Valid accounts are typically 8 digits long.": "The Bank Account number entered is invalid. Valid accounts are typically 8 digits long.",
  "Top": "Top",
  "Back to top": "Back to top",
  "Cellar Plan Signup - Confirmation": "Cellar Plan Signup - Confirmation",
  "Please check the summary below carefully and click the Submit button at the bottom of this page to complete your sign up.": "Please check the summary below carefully and click the Submit button at the bottom of this page to complete your sign up.",
  "If you wish to amend any details, please use the clickable link(s) directly above.": "If you wish to amend any details, please use the clickable link(s) directly above.",
  "Email:": "Email:",
  "Phone Number:": "Phone Number:",
  "Member\'s Name:": "Member\'s Name:",
  "Bid price": "Bid price",
  "Bid total": "Bid total",
  "Bid quantity": "Bid quantity",
  "Bids on my wines": "Bids on my wines",
  "Bids received": "Bids received",
  "Bids rejected": "Bids rejected",
  "Bids placed": "Bids placed",
  "Billing Address:": "Billing Address:",
  "Delivery Address:": "Delivery Address:",
  "Terms and Conditions": "Terms and Conditions",
  "When you start a cellar of fine wine, you\'re investing in a collection that will bring pleasure for years to come. We are sometimes asked about collecting wine and spirits for financial gain. Helping customers collect with the sole intention of achieving financial return is not something we encourage. Instead, our primary goal is to help them collect wine to enjoy in the future. The fine wines and spirits we sell are often available only in limited quantity and improve with age. Some fine wines can increase in value over the long-term (usually at least 10-15 years), and there is a secondary market for fine wines, including through BBX, our fine wine exchange.": "When you start a cellar of fine wine, you\'re investing in a collection that will bring pleasure for years to come. We are sometimes asked about collecting wine and spirits for financial gain. Helping customers collect with the sole intention of achieving financial return is not something we encourage. Instead, our primary goal is to help them collect wine to enjoy in the future. The fine wines and spirits we sell are often available only in limited quantity and improve with age. Some fine wines can increase in value over the long-term (usually at least 10-15 years), and there is a secondary market for fine wines, including through BBX, our fine wine exchange.",
  "So, while a balanced collection of fine wines and spirits can be a relatively stable investment, past performance is no guarantee of future results and we don\'t provide any promises about the potential increase in a wine\'s value, nor do we provide financial or investment advice of any kind. Please note that wine investment is not regulated by the Financial Conduct Authority.": "So, while a balanced collection of fine wines and spirits can be a relatively stable investment, past performance is no guarantee of future results and we don\'t provide any promises about the potential increase in a wine\'s value, nor do we provide financial or investment advice of any kind. Please note that wine investment is not regulated by the Financial Conduct Authority.",
  "Furthermore, I confirm my agreement of Berry Bros. & Rudd\'s terms and conditions and acknowledge that any purchase of goods or services I make will be from Berry Bros. & Rudd ltd. In addition, I agree to return or pay for any free cases of wine I receive upon joining Cellar Plan if I cancel my plan within the first 12 months or do not purchase wine with my 12 monthly payments.": "Furthermore, I confirm my agreement of Berry Bros. & Rudd\'s terms and conditions and acknowledge that any purchase of goods or services I make will be from Berry Bros. & Rudd ltd. In addition, I agree to return or pay for any free cases of wine I receive upon joining Cellar Plan if I cancel my plan within the first 12 months or do not purchase wine with my 12 monthly payments.",
  "I agree": "I agree",
  "Submit": "Submit",
  "If you wanna continue, agree with the Terms & Conditions.": "If you wanna continue, agree with the Terms & Conditions.",
  "Something went wrong, please try again later": "Something went wrong, please try again later",
  "Something went wrong there": "Something went wrong there",
  "Your data has been successfully sent. We will contact you after confirmation.": "Your data has been successfully sent. We will contact you after confirmation.",
  "Buying options": "Buying options",
  "BB&R": "BB&R",
  "BBX": "BBX",
  "For Delivery": "For Delivery",
  "For Storage In Bond": "For Storage In Bond",
  "Items for delivery": "Items for delivery",
  "Items for storage In Bond": "Items for storage In Bond",
  "Events": "Events",
  "A member of our team will contact you 7-10 days before the event with more information.": "A member of our team will contact you 7-10 days before the event with more information.",
  "Gift Vouchers": "Gift Vouchers",
  "Gift vouchers will be sent by Royal Mail.": "Gift vouchers will be sent by Royal Mail.",
  "Not available": "Not available",
  "Not ready": "Not ready",
  "There are no products available to purchase for delivery": "There are no products available to purchase for delivery",
  "Available for delivery or collection. Prices includes duty and VAT.": "Available for delivery or collection. Prices includes duty and VAT.",
  "Delivery options detailed information": "Delivery options detailed information",
  "See delivery options.": "See delivery options.",
  "There are no products available to purchase In Bond": "There are no products available to purchase In Bond",
  "Charges apply detailed information": "Charges apply detailed information",
  "Storage charges": "Storage charges",
  "apply.": "apply.",
  "Your credit balance: {0}": "Your credit balance: {0}",
  "Read less": "Read less",
  "Read more": "Read more",
  "Show Less": "Show Less",
  "Buy": "Buy",
  "About": "About",
  "Critics review": "Critics review",
  "Critics score summary": "Critics score summary",
  "Overview": "Overview",
  "Product attributes": "Product attributes",
  "Free {deliveryType} on delivery items over {deliveryAmount}": "Free {deliveryType} on delivery items over {deliveryAmount}",
  "Free": "Free",
  "Delivery free": "Delivery free",
  "standard delivery": "standard delivery",
  "on orders over £200": "on orders over £200",
  "In Bond purchases can be stored in our": "In Bond purchases can be stored in our",
  "Wine storage": "Wine storage",
  "Covered by our {guarantee}.": "Covered by our {guarantee}.",
  "General terms": "General terms",
  "quality guarantee": "quality guarantee",
  "Critics reviews": "Critics reviews",
  "Filter": "Filter",
  "View:": "View:",
  "Great news, you are the highest bidder.": "Great news, you are the highest bidder.",
  "Grid": "Grid",
  "List": "List",
  "Minimum bid ": "Minimum bid ",
  "More sizes available": "More sizes available",
  "About this wine": "About this wine",
  "About this spirit": "About this spirit",
  "About this drink": "About this drink",
  "Add Item": "Add Item",
  "Your basket is empty": "Your basket is empty",
  "Looks like you haven’t added anything to your basket yet.": "Looks like you haven’t added anything to your basket yet",
  "Continue": "Continue",
  "Continue shopping": "Continue shopping",
  "Item added to basket": "Item added to basket",
  "Sorry, there is insufficient stock for your cart.": "Sorry, there is insufficient stock for your cart.",
  "Basket Sub-total": "Basket Sub-total",
  "Basket ID: {0}": "Basket ID: {0}",
  "Sub-total": "Sub-total",
  "View Basket & Checkout": "View Basket & Checkout",
  "Continue Shopping": "Continue Shopping",
  "My account": "My account",
  "My cases listed": "My cases listed",
  "My cellar": "My cellar",
  "My BBX": "My BBX",
  "Cellar": "Cellar",
  "Current page": "Current page",
  "Show My cellar submenu": "Show My cellar submenu",
  "Show My BBX submenu": "Show My BBX submenu",
  "Cellar overview": "Cellar overview",
  "Cellar view": "Cellar view",
  "Valuation definitions": "Valuation definitions",
  "Valuation view": "Valuation view",
  "Cellar summary": "Cellar summary",
  "Valuation": "Valuation",
  "Cellar valuation info": "Cellar valuation info",
  "Request a full appraisal": "Request a full appraisal",
  "For a more detailed cellar appraisal or advice if you are considering selling some of your wines, please {action}.": "For a more detailed cellar appraisal or advice if you are considering selling some of your wines, please {action}.",
  "contact our BBX team": "contact our BBX team",
  "Stored status": "Stored status",
  "My store credit": "My store credit",
  "Format": "Format",
  "Case": "Case",
  "Selling": "Selling",
  "Case format": "Case format",
  "Case quantity": "Case quantity",
  "Cases": "Cases",
  "Price paid": "Price paid",
  "Price per case": "Price per case",
  "Value change": "Value change",
  "Market price": "Market price",
  "Market value": "Market value",
  "My list price": "My list price",
  "Not listed": "Not listed",
  "My listed wines": "My listed wines",
  "My listings": "My listings",
  "Highest bid": "Highest bid",
  "Highest bid price": "Highest bid price",
  "List another wine": "List another wine",
  "List for sale with BBX": "List for sale with BBX",
  "Edit listing details": "Edit listing details",
  "Remove listing": "Remove listing",
  "Sell now, accept highest bid": "Sell now, accept highest bid",
  "Sell your wine": "Sell your wine",
  "Your cellar is currently empty.": "Your cellar is currently empty.",
  "Showing {0}-{1} of {2} items": "Showing {0}-{1} of {2} items",
  'items per page': 'items per page',
  "-": "-", // In case another language would like to represent and empty state with another string
  "View more": "View more",
  "View less": "View less",
  "See more details": "See more details",
  "Arrange delivery": "Arrange delivery",
  "Selling options": "Selling options",
  "Selling options for": "Selling options for",
  "Arrange for delivery": "Arrange for delivery",
  "Arrange delivery for": "Arrange delivery for",
  "Withdraw options": "Withdraw options",
  "Cases": "Cases",
  "Bottles": "Bottles",
  "Withdraw all": "Withdraw all",
  "+{0} part case administration fee": "+{0} part case administration fee",
  "Increment quantity": "Increment quantity",
  "Decrement quantity": "Decrement quantity",
  "Market price guidance": "Market price guidance",
  "There is a problem": "There is a problem",
  "There is no market price available for this item": "There is no market price available for this item",
  "There is no data available for this item": "There is no data available for this item",
  "Cellar items": "Cellar items",
  "Cellar valuations": "Cellar valuations",
  "Download list (CSV)": "Download list (CSV)",
  "wine": "wine",
  "wines": "wines",
  "spirit": "spirit",
  "Show spirits in overview": "Show spirits in overview",
  "spirits": "spirits",
  "Cellar cost": "Cellar cost",
  "Cellar valuation": "Cellar valuation",
  "Value gain / loss": "Value gain / loss",
  "Region": "Region",
  "Type": "Type",
  "Stored Status": "Stored Status",
  "The text alternative for this canvas is the set of links provided below": "The text alternative for this canvas is the set of links provided below",
  "Maturity filters with pie chart graphic": "Maturity filters with pie chart graphic",
  "Maturity filters": "Maturity filters",
  "Maturity status definitions": "Maturity status definitions",
  "Pie chart of wine maturity.": "Pie chart of wine maturity.",
  "View cellar for": "View cellar for",
  "Share to {0}": "Share to {0}",
  "Type filters with pie chart graphic": "Type filters with pie chart graphic",
  "Type filters": "Type filters",
  "Pie chart of the type of item.": "Pie chart of the type of item.",
  "Stored status filters": "Stored status filters",
  "Pie chart of the stored status of item.": "Pie chart of the stored status of item.",
  'Spirits cannot currently be sold with BBX.': 'Spirits cannot currently be sold with BBX.',
  'Only complete cases can be sold with BBX. Part cases cannot be sold.': 'Only complete cases can be sold with BBX. Part cases cannot be sold.',
  'Only In Bond wines can be listed for sale with BBX. As this wine is Duty Paid, it cannot be sold with BBX.': 'Only In Bond wines can be listed for sale with BBX. As this wine is Duty Paid, it cannot be sold with BBX.',
  'This wine is not available for delivery and cannot be sold with BBX as it has not arrived in our warehouse yet.': 'This wine is not available for delivery and cannot be sold with BBX as it has not arrived in our warehouse yet.',
  'This wine is stored on behalf of a beneficial owner. To get help, contact the customer service team here.': 'This wine is stored on behalf of a beneficial owner. To get help, contact the customer service team here.',
  "This wine cannot be sold with BBX. To get help, contact the customer service team here.": "This wine cannot be sold with BBX. To get help, contact the customer service team here.",
  "Disabled BID placement slot": "Disabled BID placement slot",
  "Disabled price slot": "Disabled price slot",
  "Disabled reviews slot": "Disabled reviews slot",
  "Are you sure you want to remove all items from the Wishlist?": "Are you sure you want to remove all items from the Wishlist?",
  "You're about to remove all of your saved items from the wishlist. Please confirm below if you wish to proceed with that action.": "You're about to remove all of your saved items from the wishlist. Please confirm below if you wish to proceed with that action.",
  "Added to wishlist.": "Added to wishlist.",
  "Go to my wishlist": "Go to my wishlist",
  "Removed from wishlist.": "Removed from wishlist.",
  "Something went wrong": "Something went wrong",
  "Login as customer error": "Login as customer error",
  "You have been successfully logged in as an admin": "You have been successfully logged in as an admin",
  "Login as customer success": "Login as customer success",
  "You are connected as {0}": "You are connected as {0}",
  "Close case format select": "Close case format select",
  "Close Session[X]": "Close Session[X]",
  "Add to basket": "Add to basket",
  "More information": "More information",
  "The 3 values below are absolute and refer to specific activity on BBX": "The 3 values below are absolute and refer to specific activity on BBX",
  "These are your bids placed on this item, to view all of your BBX bids please to go to BBX bids placed.": "These are your bids placed on this item, to view all of your BBX bids please to go to BBX bids placed.",
  "These prices have been adjusted to reflect the case size and format you hold in your cellar": "These prices have been adjusted to reflect the case size and format you hold in your cellar",
  "Browse": "Browse",
  "Select Area": "Select Area",
  "Filter By Maturity": "Filter By Maturity",
  "French vintages": "French vintages",
  "World wine vintages": "World wine vintages",
  "All maturities": "All maturities",
  "Ready, but will improve": "Ready, but will improve",
  "Ready, but will keep": "Ready, but will keep",
  "Ready - youthful": "Ready - youthful",
  "Ready - at best": "Ready - at best",
  "Ready - mature": "Ready - mature",
  "Drink now": "Drink now",
  "For laying down": "For laying down",
  "Duty paid": "Duty paid",
  "In bond": "In bond",
  "Lying abroad": "Lying abroad",
  "Bid alerts above {0} (from {1} below Liv-ex market price which is currently {2})": "Bid alerts above {0} (from {1} below Liv-ex market price which is currently {2})",
  "Bid alerts on this listing": "Bid alerts on this listing",
  "Bid case quantity": "Bid case quantity",
  "To be notified of bids below your listing price, enter the lowest price you'd be interested in hearing about.": "To be notified of bids below your listing price, enter the lowest price you'd be interested in hearing about.",
  "Scroll table horizontally for more vintage years": "Scroll table horizontally for more vintage years",
  "Add To Basket": "Add To Basket",
  "Sold out": "Sold out",
  "I authorise Berry Bros. & Rudd to manage the sale of this wine based on the {terms}.": "I authorise Berry Bros. & Rudd to manage the sale of this wine based on the {terms}.",
  "I authorise Berry Bros. & Rudd to charge my default card should my bid be successful and agree to the {terms} of bidding with BBX. Please note all wines are In Bond, so the price shown does not include Duty and VAT.": "I authorise Berry Bros. & Rudd to charge my default card should my bid be successful and agree to the {terms} of bidding with BBX. Please note all wines are In Bond, so the price shown does not include Duty and VAT.",
  "terms and conditions": "terms and conditions",
  "How do we calculate market price?": "How do we calculate market price?",
  "How has this item been trending on BBX?": "How has this item been trending on BBX?",
  "How many cases would you like?": "How many cases would you like?",
  "How many cases would you like to sell?": "How many cases would you like to sell?",
  "How much would you like to bid per case?": "How much would you like to bid per case?",
  "You might also like": "You might also like",
  "Unable to get received bids, please try again.": "Unable to get received bids, please try again.",
  "Understanding these figures.": "Understanding these figures.",
  "Unfortunately your bid didn’t work, please try again.": "Unfortunately your bid didn’t work, please try again.",
  "Unfortunately your sale didn’t work, please try again.": "Unfortunately your sale didn’t work, please try again.",
  "BBX activity": "BBX activity",
  "What is BBX?": "What is BBX?",
  "Bidding": "Bidding",
  "You can bid on any wine that is stored with us, whether or not it has been actively listed for sale.": "You can bid on any wine that is stored with us, whether or not it has been actively listed for sale.",
  "BBX is our online fine wine exchange. You can buy and bid on other customer wines and sell from your own collection.": "BBX is our online fine wine exchange. You can buy and bid on other customer wines and sell from your own collection.",
  "You can list any wine for sale for free as long as it is stored with us In Bond.There is a 10% commission on every successful sale.": "You can list any wine for sale for free as long as it is stored with us In Bond. There is a 10% commission on every successful sale.",
  "BBX lowest list price": "BBX lowest list price",
  "BBX last sale": "BBX last sale",
  "Wider market valuations:": "Wider market valuations:",
  "Wider market valuations": "Wider market valuations",
  "Liv-ex market price": "Live-ex market price",
  "BBX activity for this wine": "BBX activity for this wine",
  "Wine-Searcher lowest list price": "Wine-Searcher lowest list price",
  "You have no wines currently listed": "You have no wines currently listed",
  "Product name": "Product name",
  "Make the most of BBX": "Make the most of BBX",
  "There is no maturity data available": "There is no maturity data available",
  "There is no region data available": "There is no region data available",
  "There is no type data available": "There is no type data available",
  "There is no stored status data available": "There is no stored status data available",
  "Maturity filters are unavailable for cellars containing only spirits.": "Maturity filters are unavailable for cellars containing only spirits.",
  "The requested qty exceeds the maximum qty allowed in shopping cart": "You've reached the maximum quantity allowed for this product",
  "Due to limited quantities, this event has been restricted to {0} tickets per customer.": "Due to limited quantities, this event has been restricted to {0} tickets per customer.",
  "Time": "Time",
  "Ticket Limit": "Ticket Limit",
  "Host": "Host",
  "Quick actions": "Quick actions",
  "Choose quick actions": "Choose quick actions",
  "Toggle selection on all table items": "Toggle selection on all table items",
  "Accept bid": "Accept bid",
  "Accept highest bid": "Accept highest bid",
  "Accepted bid": "Accepted bid",
  "Reject highest bid": "Reject highest bid",
  "Reject highest bids": "Reject highest bids",
  "Edit listing": "Edit listing",
  "Remove listings": "Remove listings",
  "Join Waitlist": "Join Waitlist",
  "Firstname": "Firstname",
  "Phone Number": "Phone Number",
  "Surname": "Surname",
  "E-mail": "E-mail",
  "Event name and date": "Event name and date",
  "Number of tickets required": "Number of tickets required",
  "I have read and accept the Privacy Policy": "I have read and accept the Privacy Policy",
  "Comments": "Comments",
  "(Optional)": "(Optional)",
  "Register Interest": "Register Interest",
  "Register your Interest": "Register your Interest",
  "This event is fully subscribed. Please complete the form below and we'll contact you if there are any cancellations.": "This event is fully subscribed. Please complete the form below and we'll contact you if there are any cancellations.",
  "This graph gives an indication of changing demand for this item. Please note for rare or unusual items, trends are harder to assess due to less frequent sales. Hover over the white dots to see sale prices, which have been adjusted to reflect the case size and format you own.": "This graph gives an indication of changing demand for this item. Please note for rare or unusual items, trends are harder to assess due to less frequent sales. Hover over the white dots to see sale prices, which have been adjusted to reflect the case size and format you own.",
  "I have read and accept the {terms}.": "I have read and accept the {terms}.",
  "Enter a comment": "Enter a comment",
  "Please enter a first name": "Please enter a first name",
  "Please enter a surname": "Please enter a surname",
  "Please enter a phone number": "Please enter a phone number",
  "Please enter a valid phone number": "Please enter a valid phone number",
  "Please enter an email address": "Please enter an email address",
  "Please accept the terms and conditions": "Please accept the terms and conditions",
  "Remove all": "Remove all",
  "Places available": "Places available",
  "e.g.: ": "e.g.: ",
  "drink": "drink",
  "Switch account": "Switch account",
  "Profile": "Profile",
  "Address book": "Address book",
  "Manage payments": "Manage payments",
  "Orders, payments & statements": "Orders, payments & statements",
  "Interests": "Interests",
  "Communication preferences": "Communication preferences",
  "The customer preferences has been saved": "The customer preferences has been saved",
  "An error occurred while saving the customer preferences": "An error occurred while saving the customer preferences",
  "We want all our communications to be relevant and interesting to you and avoid sending you things that aren’t. If you no longer wish to hear about our latest offers, products and services, you can opt out at any time by checking the boxes below.": "We want all our communications to be relevant and interesting to you and avoid sending you things that aren’t. If you no longer wish to hear about our latest offers, products and services, you can opt out at any time by checking the boxes below.",
  "I do not want to receive emails": "I do not want to receive emails",
  "I do not want to receive post": "I do not want to receive post",
  "Save Preferences": "Save Preferences",
  "To find out how we keep your personal details safe, please read our {privacy}": "To find out how we keep your personal details safe, please read our {privacy}",
  "Store credit": "Store credit",
  "Show My account submenu": "Show my account submenu",
  "Update the address": "Update the address",
  "Please enter a title": "Please enter a title",
  "Alternative number": "Alternative number",
  "Add alternative phone number": "Add alternative phone number",
  "Enter address manually": "Enter address manually",
  "Save Address": "Save Address",
  "Postcode": "Postcode",
  "Manage-payments": "Manage-payments",
  "Address Book": "Address Book",
  "Address name": "Address name",
  "Address details": "Address details",
  "e.g. Home": "e.g. Home",
  "Address Line 1": "Address Line 1",
  "Address Line 2": "Address Line 2",
  "Town/City": "Town/City",
  "We've added a new address to your account": "We've added a new address to your account",
  "We've updated your address": "We've updated your address",
  "Use this as my contact address": "Use this as my contact address",
  "Use this as my default delivery address": "Use this as my default delivery address",
  "My additional addresses ({0})": "My additional addresses ({0})",
  "Are you sure you would like to remove this address?": "Are you sure you would like to remove this address?",
  "You are about to remove this listing from BBX": "You are about to remove this listing from BBX",
  "Reject bid": "Reject bid",
  "Reject bids": "Reject bids",
  "Are you sure you want to reject the selected bids? Please note all lower bids will also be rejected.": "Are you sure you want to reject the selected bids? Please note all lower bids will also be rejected.",
  "Are you sure you want to reject the selected bid? Please note all lower bids will also be rejected.": "Are you sure you want to reject the selected bid? Please note all lower bids will also be rejected.",
  "Are you sure you want to reject this bid? Please note all lower bids will also be rejected": "Are you sure you want to reject this bid? Please note all lower bids will also be rejected",
  "Remove bids": "Remove bids",
  "Remove bid": "Remove bid",
  "Are you sure you want to remove the selected bids? You can always place a bid at another time.": "Are you sure you want to remove the selected bids? You can always place a bid at another time.",
  "Are you sure you want to remove the selected bid? You can always place a bid at another time.": "Are you sure you want to remove the selected bid? You can always place a bid at another time.",
  "Are you sure you want to remove the selected listings?": "Are you sure you want to remove the selected listings?",
  "Are you sure you want to remove the selected listing?": "Are you sure you want to remove the selected listing?",
  "Unable to remove from BBX, please try again.": "Unable to remove from BBX, please try again.",
  "Error when removing listings": "Error when removing listings",
  "Error when removing listing": "Error when removing listing",
  "You have successfully rejected the bids.": "You have successfully rejected the bids.",
  "You have successfully removed your listings from BBX.": "You have successfully removed your listings from BBX.",
  "You have successfully removed your listing from BBX.": "You have successfully removed your listing from BBX.",
  "Listings removed": "Listings removed",
  "Listing removed": "Listing removed",
  "Remove listing confirmation for {0}": "Remove listing confirmation for {0}",
  "Quick Links": "Quick Links",
  "My bid": "My bid",
  "Apply code": "Apply code",
  "Remove code": "Remove code",
  "Highest bid": "Highest bid",
  "My bid placed": "My bid placed",
  "Cases available": "Cases available",
  "Cases requested": "Cases requested",
  "My bid quantity": "My bid quantity",
  "My bid price": "My bid price",
  "Bidding activity": "Bidding activity",
  "per case": "per case",
  "First name": "First name",
  "Last name": "Last name",
  "Email": "Email",
  "Personal Information": "Personal Information",
  "Login Information": "Login Information",
  "Edit personal information": "Edit personal information",
  "Edit email address": "Edit email address",
  "Change my password": "Change my password",
  "Zip / Postal code": "Zip / Postal code",
  "My account invoice address": "My account invoice address",
  "State / Province / Region": "State / Province / Region",
  "state / province / region": "state / province / region",
  "Default delivery address": "Default delivery address",
  "Default contact address": "Default contact address",
  "Please use this form to update your personal details": "Please use this form to update your personal details",
  "Save updates": "Save updates",
  "Start typing and select your address": "Start typing and select your address",
  "Disabled cta button": "Disabled cta button",
  "Change topic": "Change topic",
  "Account links": "Account links",
  "Save alert settings": "Save alert settings",
  "Bid alerts for my entire cellar": "Bid alerts for my entire cellar",
  "You can choose to receive e-mail alerts and app push notifications when bids are made on wines you have stored with Berry Bros. & Rudd. You can create an alert to apply to all of your cellared wines.": "You can choose to receive e-mail alerts and app push notifications when bids are made on wines you have stored with Berry Bros. & Rudd. You can create an alert to apply to all of your cellared wines.",
  "Receive bid alerts": "Receive bid alerts",
  "We can not retrieve your address details automatically. Please enter the address manually.": "We can not retrieve your address details automatically. Please enter the address manually.",
  "Which threshold would you like to use?": "Which threshold would you like to use?",
  "Percentage below Liv-ex market price": "Percentage below Liv-ex market price",
  "Percentage above lowest purchase price": "Percentage above lowest purchase price",
  "No threshold - receive all bids": "No threshold - receive all bids",
  "Threshold percentage": "Threshold percentage",
  "Default bid alert threshold is 15% below the Liv-ex market price": "Default bid alert threshold is 15% below the Liv-ex market price",
  "This is the main address linked with your Berry Bros. & Rudd account. We will use this as your default delivery and contact address unless you select an alternative address.": "This is the main address linked with your Berry Bros. & Rudd account. We will use this as your default delivery and contact address unless you select an alternative address.",
  "My account address": "My account address",
  "Add an address": "Add an address",
  "It looks like you haven't added any details to your address book yet.": "It looks like you haven't added any details to your address book yet.",
  "Address nickname": "Address nickname",
  "Unable to remove address": "Unable to remove address",
  "Your default delivery address can not be removed. Please set an alternative address as your default delivery address first and try again.": "Your default delivery address can not be removed. Please set an alternative address as your default delivery address first and try again.",
  "We've removed an address from your account": "We\'ve removed an address from your account",
  "Logo": "Logo",
  "Search action icon": "Search action icon",
  "Account action icon": "Account action icon",
  "Cart action icon": "Cart action icon",
  "Wishlist action icon": "Wishlist action icon",
  "Menu action icon": "Menu action icon",
  "Main menu": "Main menu",
  "Secure payment": "Secure payment",
  "Royal warrant by appointment to H.R.H the Prince of Wales wine & spirit merchant London": "Royal warrant by appointment to H.R.H the Prince of Wales wine & spirit merchant London",
  "Royal warrant by appointment to H.M The Queen wine & spirit merchant London": "Royal warrant by appointment to H.M The Queen wine & spirit merchant London",
  ...searchTranslations,
  ...checkoutTranslations,
  "Opening hours (GMT)": "Opening hours (GMT)",
  "Telephone:": "Telephone:",
  "En Primeur notification": "En Primeur notification",
  "Information about En Primeur purchase goes here and explains that this will be delivered to the customers cellar when ready and will then incur storage charges": "Information about En Primeur purchase goes here and explains that this will be delivered to the customers cellar when ready and will then incur storage charges",
  "We\'ll send updates to": "We\'ll send updates to",
  "A confirmation email has been sent to": "A confirmation email has been sent to",
  "I\'d like to hear about wines and spirits offers, new products and producers, as well as events and services by email (optional)": "I\'d like to hear about wines and spirits offers, new products and producers, as well as events and services by email (optional)",
  "I\'d like to hear about wines and spirits offers, new products and producers, as well as events and services by email": "I\\'d like to hear about wines and spirits offers, new products and producers, as well as events and services by email",
  "You need to accept the {0} to sign up.": "You need to accept the {0} to sign up.",
  "Search for a country or a region": "Search for a country or a region",
  "Search for producers": "Search for producers",
  "Search for grapes": "Search for grapes",
  "Browse regions": "Browse regions",
  "Browse {0}": "Browse {0}",
  "Sorry, we can't find any results for “{0}”": "Sorry, we can't find any results for “{0}”",
  "Sorry, we can\'t find any results": "Sorry, we can\'t find any results",
  "Try different keywords or broaden your search. If you still can't find what you're looking for, please {0}.": "Try different keywords or broaden your search. If you still can't find what you're looking for, please {0}.",
  "We couldn\'t find any matching products. Try {0} instead": "We couldn't find any matching products. Try {0} instead",
  "We couldn't find any matching products. Try {browse} instead.": "We couldn\'t find any matching products. Try {browse} instead.",
  "browsing our other {0}": "browsing our other {0}",
  "Full Name": "Full Name",
  "Send enquiry": "Send enquiry",
  "Contact Form": "Contact Form",
  'All results for `{0}`': 'All results for `{0}`',
  "I need help with...": "I need help with...",
  "Account No": "Account No",
  "Thank you for your query": "Thank you for your query",
  "We aim to respond within 48 hours. However, at peak times and bank holidays this may vary.": "We aim to respond within 48 hours. However, at peak times and bank holidays this may vary.",
  "Return to homepage": "Return to homepage",
  "Explore Customer support": "Explore Customer support",
  "We can not process your enquiry at the moment": "We can not process your enquiry at the moment",
  "Please try again later or get in touch with us via email, phone or live chat": "Please try again later or get in touch with us via email, phone or live chat",
  "Mon - Fri:": "Mon - Fri:",
  "Sat:": "Sat:",
  "Sun & Public Holidays:": "Sun & Public Holidays:",
  "{0} filters with pie chart graphic": "{0} filters with pie chart graphic",
  "{0} filters": "{0} filters",
  "View all regions": "View all regions",
  "View less regions": "View less regions",
  "You can place a bid for this wine with BBX": "You can place a bid for this wine with BBX",
  "Bid": "Bid",
  "Search my cellar": "Search my cellar",
  "Search my listings": "Search my listings",
  "Search bids received": "Search bids received",
  "Part case administration fee": "Part case administration fee",
  "Withdrawals of part cases are charged at {0} per product.": "Withdrawals of part cases are charged at {0} per product.",
  "Invalid password. Please try again.": "Invalid password. Please try again.",
  "My statements": "My statements",
  "There is no monthly statement to view": "There is no monthly statement to view",
  "Your monthly statements will appear here if you have any transactions within the month.": "Your monthly statements will appear here if you have any transactions within the month.",
  "Check the details and status of your orders in the online store. You can also cancel your order or request a return.": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  " - No Transactions": " - No Transactions",
  "Statements from 2020 or earlier are not available online. Please contact customer service if you require older transaction details.": "Statements from 2020 or earlier are not available online. Please contact customer service if you require older transaction details.",
  "Available In Bond": "Available In Bond",
  "Purchase price low to high": "Purchase price low to high",
  "Purchase price high to low": "Purchase price high to low",
  "Producer name A-Z": "Producer name A-Z",
  "Your sale price low to high": "Your sale price low to high",
  "Your sale price high to low": "Your sale price high to low",
  "Bid price low to high": "Bid price low to high",
  "Bid price high to low": "Bid price high to low",
};
