import { Plugin } from '@nuxt/types';
import { AmplienceModuleOptions } from '@amplience/types';

declare module 'vue/types/vue' {
  interface Vue {
    $amplience: {
      headerDeliveryKey: string;
      footerDeliveryKey: string;
      globalHeaderDeliveryKey: string;
      globalFooterDeliveryKey: string;
    };
  }
}

declare module '@nuxt/types' {
  interface Context {
    $amplience: {
      headerDeliveryKey: string;
      footerDeliveryKey: string;
      globalHeaderDeliveryKey: string;
      globalFooterDeliveryKey: string;
    };
  }
}

const ampliencePlugin: Plugin = (context, inject) => {
  const options = JSON.parse('{"headerDeliveryKey":"prodsupp-header","footerDeliveryKey":"prodsupp-footer","globalHeaderDeliveryKey":"prodsupp-global-header","globalFooterDeliveryKey":"prodsupp-global-footer"}') as AmplienceModuleOptions;
  const {
    headerDeliveryKey,
    footerDeliveryKey,
    globalHeaderDeliveryKey,
    globalFooterDeliveryKey,
  } = options;

  inject('amplience', {
    headerDeliveryKey,
    footerDeliveryKey,
    globalHeaderDeliveryKey,
    globalFooterDeliveryKey,
  });
};

export default ampliencePlugin;
